import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />

    <div className="container pd-top-120 ">

    <h1 className="pd-top-60 pd-bottom-60">Errore 404</h1>

    </div>
  </Layout>
)

export default NotFoundPage
